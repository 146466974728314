@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Veneer';
  src: url('/fonts/2DCD83_0_0.eot');
  src: url('/fonts/2DCD83_0_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/2DCD83_0_0.woff2') format('woff2'),
    url('/fonts/2DCD83_0_0.woff') format('woff'),
    url('/fonts/2DCD83_0_0.ttf') format('truetype');
}

/* Richtext from CMS */

.richtext p:not(:last-child),
.richtext ul:not(:last-child),
.richtext ol:not(:last-child) {
  margin-bottom: 25px;
}

.richtext a {
  color: #0a4056;
  text-decoration: underline;
}

/* Slick slider */
.slick-slider .slick-dots {
  bottom: auto;
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  content: none;
}

/* Add to favorites */
.confetti {
  position: relative;
}

.confetti i {
  animation: confetti 0.6s ease-out forwards;
  position: absolute;
  display: block;
  width: 4px;
  height: 4px;
  top: 50%;
  left: 50%;
  margin: -2px 0 0 -2px;
  opacity: var(--o, 0);
  background: var(--b);
  transform: translate(var(--x), var(--y)) scale(var(--s, 1));
}

@-webkit-keyframes confetti {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes confetti {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.animate-twice {
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
}

/* Show sibling SVG when checking a checkbox */
input:checked+svg {
  @apply block;
}


/* Helper to zoom card image */
.card-zoom:hover .card-zoom-image {
  @apply scale-125;
}

.card-zoom-image {
  @apply transition-all duration-500 ease-in-out transform;
}