/* Customize VideoJS overlay play button */
.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 2.5em;
  height: 2.5em;
  width: 2.5em;
  border: 0.06666em solid #fff;
  border-radius: 1.5em;
  left: 50%;
  top: 50%;
  margin-left: -1.25em;
  margin-top: -1.3888888889em;
}

.vjs-control.vjs-button:hover {
  background-color: rgb(10 64 86 / 33%);
}

/* Increase Chromecast plugin icon size */
.vjs-chromecast-button.vjs-button .vjs-icon-placeholder {
  height: 16px;
  width: 16px;
}
